import React from "react"
import PropTypes from "prop-types"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeader from '../../components/_shared/PageHeader';

import { graphql } from "gatsby"

const BookerPrizeWinneres = ({
  data: {
    allBookerPrizeWinnersCsv: { edges },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  return (
    <Layout>
      <SEO title="Booker Prize Winners" />
      <article class="mainArticle">
        <PageHeader title="Booker Prize Winners" preTitle="Mini Project"/>
        <div class="articleBody">
          <table>
            <thead>
              <tr>
                <th>Author</th>
                <th>Book</th>
                <th>Year</th>
                <th>Read</th>
              </tr>
            </thead>
            <tbody>
              {edges.map(({ node }) => {
                return (
                  <tr>
                    <td>{node.Author}</td>
                    <td>{node.Book}</td>
                    <td>{node.Year}</td>
                    <td>{node.Read}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </article>
    </Layout>
  )
}

BookerPrizeWinneres.propTypes = {
  data: PropTypes.shape({
    allBookerPrizeWinnersCsv: PropTypes.object,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default BookerPrizeWinneres




export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allBookerPrizeWinnersCsv {
    edges {
      node {
        id
        Author
        Book
        Read
        Year
      }
    }
  }
}
`;